<template>
  <div v-if="data" class="page product-page page-sections__even">
    <BreadcrumbsComponent :links="links" :title="data.title" />
    <div class="page__inner">
      <SectionComponent>
        <div class="product-page__body">
          <ProductGalleryComponent
            :items="images"
            :head_img="head_img"
            :default-alt="data.title"
            class="product-page__gallery"
          />
          <div class="product-page__col">
            <div class="product-page__articul" v-if="active_set.articul">
              Артикул: {{ active_set.articul }}
            </div>
            <template v-if="data.product_sets && data.product_sets.length">
              <template v-for="(key, index) in Object.keys(attributes)">
                <div v-if="attributes[key].values.length" :key="index" class="product-page__attribute">
                  <span v-if="attributes[key] && attributes[key].title" class="product-page__attribute-title">
                    Выберите {{ attributes[key].title.toLowerCase() }}
                  </span>
                  <div class="product-page__attribute-values">
                    <RadioComponent
                      v-for="(item, i) in attributes[key].values"
                      :key="i"
                      @change="changeSet(key, item)"
                      :hideState="true"
                    >
                      <span
                        v-if="attributes[key].is_color"
                        class="product-page__attribute-value"
                        :style="{ 'background-color': item.hex }"
                        :class="{
                          'product-page__attribute-value--active': isAttributeChecked(key, item),
                          'product-page__attribute-value--disabled': isAttributeDisable(key, item),
                        }"
                      />
                      <span
                        v-else
                        class="product-page__attribute-value"
                        :class="{
                          'product-page__attribute-value--active': isAttributeChecked(key, item),
                          'product-page__attribute-value--disabled': isAttributeDisable(key, item),
                        }"
                      >
                        {{ item.value }}
                      </span>
                    </RadioComponent>
                  </div>
                  <a
                    v-if="attributes[key].in_size && data && data.size_table"
                    @click.prevent="showSizesTableModal"
                    class="product-page__col-link"
                    href="#"
                  >
                    Таблица размеров
                  </a>
                </div>
              </template>
            </template>
            <div
              v-if="data.attribute_values && data.attribute_values.length"
              class="product-page__specs-small"
            >
              <span>Характеристики</span>
              <ul class="product-page__specs">
                <li
                  v-for="(item, i) in data.attribute_values.slice(0, 3)"
                  :key="i"
                  class="product-page__spec"
                >
                  <span class="product-page__spec-title" v-if="item.attribute">
                    {{ item.attribute.title }}
                  </span>
                  <span class="product-page__spec-line"></span>
                  <a href="#" class="product-page__spec-value" v-if="item.link">{{ item.attribute.value }}</a>
                  <span class="product-page__spec-value" v-else>{{ item.value.value }}</span>
                </li>
              </ul>
            </div>
            <div v-if="data.brand" class="product-page__brand">
              <ImgComponent class="product-page__brand-img" :img="data.brand.head_img" />
              <div class="product-page__brand-info">
                <div class="product-page__brand-title" v-if="data.brand.title">{{ data.brand.title }}</div>
                <router-link
                  :to="{ name: 'catalog', query: { brands_item_id: data.brand.id } }"
                  class="product-page__brand-link product-page__col-link"
                >
                  Смотреть все товары
                </router-link>
              </div>
            </div>
          </div>
          <div class="product-page__col">
            <button
              @click="addToFavorites"
              type="button"
              class="product-page__favorite-add btn"
              :class="{ active: inFavorites }"
            >
              <span>{{ inFavorites ? "В избранном" : "В избранное" }}</span>
              <IconComponent name="heart" />
            </button>
            <div class="product-page__buy">
              <div class="product-page__prices">
                <span class="product-page__price">
                  <span v-if="!allSame">от</span>
                  <span v-if="active_set.product_sets && active_set.product_sets.length">
                    {{ active_set | product_price | formatPrice }}
                  </span>
                  <span v-else>{{ active_set.price | share_price(active_set) | formatPrice }}</span>
                </span>
                <span
                  class="product-page__old-price"
                  v-if="
                    (active_set.join_share && active_set.join_share.share) ||
                    (active_set.join_share_set && active_set.join_share_set.share)
                  "
                >
                  {{ active_set.price | formatPrice }}
                </span>
                <!--                <span class="product-page__bonus">-->
                <!--                  <span class="product-page__bonus-price"> 855 ₽ </span>-->
                <!--                  <span class="product-page__bonus-percent"> -5% с бонусной картой </span>-->
                <!--                </span>-->
              </div>
              <div class="product-page__actions">
                <button
                  @click="cartAdd"
                  type="button"
                  class="btn btn--sm product-page__cart-add"
                  :class="{ 'btn--red': !inCart, 'btn--green': inCart }"
                >
                  <IconComponent name="bag" />
                  <span v-if="!inCart">В корзину</span>
                  <span v-else>В корзине</span>
                </button>
                <button
                  @click.prevent="showClickBuyModal"
                  type="button"
                  class="btn btn--blue-hollow btn--sm product-page__cart-add"
                  :class="{ active: inCart }"
                >
                  Купить в 1 клик
                </button>
              </div>
            </div>
            <div class="product-page__pros">
              <div v-for="(item, i) in pros" :key="i" class="product-page__plus">
                <IconComponent :name="item.icon" />
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
        <SharesComponent title="Участвует в акциях" />
      </SectionComponent>
      <SectionComponent>
        <div class="product-page__info" :class="{ 'product-page__info--no-specs': !hasSpecs }">
          <div v-if="hasSpecs" class="product-page__specs-wrap">
            <h2 class="title">Характеристики</h2>
            <ul class="product-page__specs">
              <li v-if="data.brand && data.brand.title" class="product-page__spec">
                <span class="product-page__spec-title">Бренд</span>
                <span class="product-page__spec-line" />
                <span class="product-page__spec-value">{{ data.brand.title }}</span>
              </li>
              <li v-for="(item, i) in data.attribute_values" :key="i" class="product-page__spec">
                <span class="product-page__spec-title" v-if="item.attribute">{{ item.attribute.title }}</span>
                <span class="product-page__spec-line"></span>
                <a href="#" class="product-page__spec-value" v-if="item.link">{{ item.value.value }}</a>
                <span class="product-page__spec-value" v-else>{{ item.value.value }}</span>
              </li>
              <li v-if="data.country && data.country.title" class="product-page__spec">
                <span class="product-page__spec-title">Страна производитель</span>
                <span class="product-page__spec-line" />
                <span class="product-page__spec-value">{{ data.country.title }}</span>
              </li>
              <li v-if="data.category && data.category.title" class="product-page__spec">
                <span class="product-page__spec-title">Категория</span>
                <span class="product-page__spec-line" />
                <span class="product-page__spec-value">{{ data.category.title }}</span>
              </li>
              <li v-if="data.articul" class="product-page__spec">
                <span class="product-page__spec-title">Артикул</span>
                <span class="product-page__spec-line" />
                <span class="product-page__spec-value">{{ data.articul }}</span>
              </li>
            </ul>
          </div>
          <div v-if="data.description" class="product-page__description-wrap">
            <h2 class="title">Описание</h2>
            <div v-if="$options.filters.editor_has_text(data.description)" class="product-page__description">
              <EditorJSComponent :text="data.description" />
            </div>
          </div>
        </div>
      </SectionComponent>
      <SectionComponent v-if="data.similar_products && data.similar_products.length">
        <ProductsComponent :products="data.similar_products" title="Похожие товары" row />
      </SectionComponent>
    </div>
  </div>
</template>

<script>
import ProductsComponent from "components/ProductsComponent.vue";
import SharesComponent from "components/SharesComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import ProductGalleryComponent from "components/product/ProductGalleryComponent.vue";
import RadioComponent from "components/inputs/RadioComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import PRODUCT_PAGE from "@/graphql/pages/ProductPage.graphql";
import ClickBuyModal from "components/modals/components/ClickBuyModal.vue";
import SizesTableModal from "components/modals/components/SizesTableModal.vue";

export default {
  name: "ProductPage",
  components: {
    EditorJSComponent,
    RadioComponent,
    ProductGalleryComponent,
    IconComponent,
    BreadcrumbsComponent,
    SectionComponent,
    SharesComponent,
    ProductsComponent,
  },
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: PRODUCT_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.state.product_page.product = data.product;
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      });
  },
  data() {
    return {
      loading: false,
      active_set: null,
      count: 1,
      attributes: {
        variation_one: {
          id: null, // id атрибута
          title: null, // заголовок атрибута
          values: [], // значения атрибута
        },
        variation_two: {
          id: null,
          title: null,
          values: [],
        },
        variation_three: {
          id: null,
          title: null,
          values: [],
        },
      },
      selected_attribute_values: {
        variation_one: null,
        variation_two: null,
        variation_three: null,
      },
      pros: [
        {
          title: "Есть в наличии",
          icon: "box",
        },
        {
          title: "Доставка по городу 1-3 дня",
          icon: "truck-fast",
        },
        {
          title: "Гарантия 12 месяцев",
          icon: "medal-star",
        },
        {
          title: "Бесплатная примерка",
          icon: "users",
        },
      ],
    };
  },
  watch: {
    // выбранные значения атрибутов, на основании них находим соответствующую вариацию, если она есть
    selected_attribute_values: {
      handler() {
        this.active_set =
          this.data.product_sets.find(
            (item) =>
              item.variation_one?.value.id === this.selected_attribute_values.variation_one?.id &&
              item.variation_two?.value.id === this.selected_attribute_values.variation_two?.id &&
              item.variation_three?.value.id === this.selected_attribute_values.variation_three?.id
          ) || this.data;
      },
      deep: true,
    },
  },
  created() {
    // по умолчанию активной вариацией является родительский товар
    this.active_set = this.data;
    if (this.data.product_sets && this.data.product_sets.length) {
      // берем названия и id атрибутов первой вариации, например цвет, размер
      if (this.data.product_sets[0].variation_one && this.data.product_sets[0].variation_one.attribute) {
        this.attributes.variation_one.title = this.data.product_sets[0].variation_one.attribute.title;
        this.attributes.variation_one.in_size = this.data.product_sets[0].variation_one.attribute.in_size;
        this.attributes.variation_one.is_color = this.data.product_sets[0].variation_one.attribute.is_color;
        this.attributes.variation_one.id = this.data.product_sets[0].variation_one.attribute.id;
      }
      if (this.data.product_sets[0].variation_two && this.data.product_sets[0].variation_two.attribute) {
        this.attributes.variation_two.title = this.data.product_sets[0].variation_two.attribute.title;
        this.attributes.variation_two.in_size = this.data.product_sets[0].variation_two.attribute.in_size;
        this.attributes.variation_two.is_color = this.data.product_sets[0].variation_two.attribute.is_color;
        this.attributes.variation_two.id = this.data.product_sets[0].variation_two.attribute.id;
      }
      if (this.data.product_sets[0].variation_three && this.data.product_sets[0].variation_three.attribute) {
        this.attributes.variation_three.title = this.data.product_sets[0].variation_three.attribute.title;
        this.attributes.variation_three.in_size = this.data.product_sets[0].variation_three.attribute.in_size;
        this.attributes.variation_three.is_color =
          this.data.product_sets[0].variation_three.attribute.is_color;
        this.attributes.variation_three.id = this.data.product_sets[0].variation_three.attribute.id;
      }
      // проходимся по вариациям и собираем все значения атрибутов для каждого атрибута variation_...
      this.data.product_sets.forEach((item) => {
        if (
          item.variation_one &&
          item.variation_one.value &&
          !this.attributes.variation_one.values.find((v) => v.id === item.variation_one.value.id)
        ) {
          this.attributes.variation_one.values.push(item.variation_one.value);
        }
        if (
          item.variation_two &&
          item.variation_two.value &&
          !this.attributes.variation_two.values.find((v) => v.id === item.variation_two.value.id)
        ) {
          this.attributes.variation_two.values.push(item.variation_two.value);
        }
        if (
          item.variation_three &&
          item.variation_three.value &&
          !this.attributes.variation_three.values.find((v) => v.id === item.variation_three.value.id)
        ) {
          this.attributes.variation_three.values.push(item.variation_three.value);
        }
      });

      // если перешли по вариации продукта, то выбираем ее активной
      let set = this.data.product_sets.find((set) => set.id === parseInt(this.$route.query.set_id));
      if (set) {
        this.selected_attribute_values.variation_one = set.variation_one?.value;
        this.selected_attribute_values.variation_two = set.variation_two?.value;
        this.selected_attribute_values.variation_three = set.variation_three?.value;
      } else {
        this.selected_attribute_values.variation_one = this.attributes.variation_one.values[0];
      }
    }
  },
  computed: {
    data() {
      return this.$store.state.product_page.product;
    },
    images() {
      return this.active_set.images && this.active_set.images.length
        ? this.active_set.images
        : this.data.images || [];
    },
    head_img() {
      return this.active_set.head_img || this.data.head_img;
    },
    inCart() {
      return this.active_set && !!this.$store.state.auth.cart.find((c) => c.item?.id === this.active_set?.id);
    },
    hasSpecs() {
      return (
        (this.data.attribute_values && this.data.attribute_values.length) ||
        (this.data.country && this.data.country.title) ||
        this.data.articul
      );
    },
    links() {
      let links = [];
      if (this.data.category) {
        let category = this.data.category;
        while (category) {
          links.unshift({
            title: category.title,
            route: {
              name: "catalog",
              params: { id: category.id },
            },
          });
          category = category.parent;
        }
      }
      links.unshift({ title: "Каталог", route: { name: "catalog" } });
      return links;
    },
    // средняя оценка продукта
    averageReviewsMark() {
      if (this.data && this.data.reviews && this.data.reviews.length) {
        let length = this.data.reviews.length;
        return Math.floor(this.data.reviews.map((r) => parseInt(r.mark)).reduce((a, b) => a + b, 0) / length);
      }
      return 0;
    },
    inFavorites() {
      return this.$store.state.auth.favorites_ids.find((k) => k === this.data.id);
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
    needVariationSelect() {
      if (this.data.product_sets && this.data.product_sets.length) {
        return !this.data.product_sets.find((item) => item.id === this.active_set.id);
      }
      return false;
    },
    allSame() {
      let sets = this.active_set.product_sets || [];
      return sets.every((i) => i.price === sets[0].price);
    },
  },
  methods: {
    showSizesTableModal() {
      this.$store.state._modals.push({
        component: SizesTableModal,
        options: {
          size_table: this.data.size_table || {},
        },
      });
    },
    // добавление в корзину
    cartAdd() {
      // если вариации есть, но ни одна не выбрана
      if (this.needVariationSelect) {
        this.$notify({
          title: "Внимание",
          text: "Выберите, пожалуйста, модель товара",
          duration: 3000,
          speed: 200,
          type: "warn",
        });
      } else {
        // если такого товара нет в корзине, то добавляем
        if (!this.inCart) {
          this.$store.state.auth.cart.unshift({
            count: this.count,
            item: this.active_set,
          });
        } else {
          this.$router.push({ name: "cart" }).then(() => {
            this.$emit("close");
          });
        }
        if (this.isRegistered && !this.loading) {
          this.$store
            .dispatch("CART_ADD_ITEMS", {
              apollo: this.$apollo,
              variables: {
                items: [
                  {
                    id: this.active_set.id,
                    count: this.count,
                  },
                ],
              },
            })
            .then(() => {
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.error(error);
            });
        }
      }
    },
    showClickBuyModal() {
      this.$store.state._modals.push({
        component: ClickBuyModal,
        options: {
          // если у товара есть вариации, то надо показывать модалку выбора вариаций,
          // потом уже модалку офрмления заказа "в один клик"
          product: this.data.id,
        },
      });
    },
    async addToFavorites() {
      if (this.isRegistered) {
        let index = this.$store.state.auth.favorites_ids.indexOf(this.data.id);
        if (index !== -1) {
          this.$store.state.auth.favorites_ids.splice(index, 1);
        } else {
          this.$store.state.auth.favorites_ids.push(this.data.id);
        }
        await this.$store.dispatch("FAVORITE_CREATE_OR_DELETE", {
          apollo: this.$apollo,
          variables: {
            item_id: this.data.id,
          },
        });
      } else {
        this.showLoginModal();
      }
    },
    changeSet(variation, val) {
      if (
        this.selected_attribute_values[variation] &&
        this.selected_attribute_values[variation].id === val.id
      ) {
        this.selected_attribute_values[variation] = null;
      } else {
        this.selected_attribute_values[variation] = val;
      }
    },
    isAttributeChecked(variation, val) {
      if (this.selected_attribute_values[variation]) {
        return this.selected_attribute_values[variation].id === val.id;
      }
      return false;
    },
    isAttributeDisable(variation, val) {
      let selected_attribute_values = JSON.parse(JSON.stringify(this.selected_attribute_values));
      selected_attribute_values[variation] = val;

      let sets = this.data.product_sets;
      if (selected_attribute_values.variation_one) {
        sets = sets.filter(
          (item) =>
            item.variation_one && item.variation_one.value.id === selected_attribute_values.variation_one.id
        );
      }
      if (selected_attribute_values.variation_two) {
        sets = sets.filter(
          (item) =>
            item.variation_two && item.variation_two.value.id === selected_attribute_values.variation_two.id
        );
      }
      if (selected_attribute_values.variation_three) {
        sets = sets.filter(
          (item) =>
            item.variation_three &&
            item.variation_three.value.id === selected_attribute_values.variation_three.id
        );
      }
      return !sets.length;
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
    isProductNew(date) {
      let oneDay = 1000 * 60 * 60 * 24;
      return date ? Math.floor((Date.now() - new Date(date).getTime()) / oneDay) <= 14 : false;
    },
  },
  metaInfo() {
    return {
      title: this.data.meta_title || this.data.title,
      titleTemplate: "%s - купить в ОРТОСИТИ",
      description: this.data.meta_description,
      keywords: this.data.meta_keywords,
    };
  },
};
</script>

<style lang="stylus">
.product-page {
  &__body {
    display grid
    grid-template-columns 1fr minmax(auto, 320px) minmax(auto, 320px)
    grid-gap var(--gap)
    align-items start
    +below(1300px) {
      grid-template-columns 1fr 1fr
    }
    +below(720px) {
      grid-template-columns 1fr
    }
  }

  &__gallery {
    +below(1300px) {
      grid-column: 1 / -1;
    }
    +below(400px) {
      flex-direction column-reverse
    }

    .product-gallery__image {
      max-width 700px
    }
  }

  &__col {
    display flex
    flex-direction column
    grid-gap 10px

    > * {
      background var(--white)
      padding 15px
      border-radius var(--big-radius)
    }

    &-link {
      font-size: 0.75em
      line-height: 14px;
      color: var(--blue)
    }
  }

  .title {
    +below(440px) {
      margin-bottom 15px
    }
  }

  &__articul {
    padding 5px 10px
    border-radius var(--small-radius)
    display flex
    gap: 5px
    align-self flex-start
    justify-content center
    text-align center
    +below(720px) {
      width 100%
    }
  }

  &__attribute,
  &__specs-small {
    display flex
    flex-direction column
    align-items flex-start
    gap: 10px
  }

  &__specs-small &__specs {
    font-size 0.875em
  }

  &__attribute {
    &-values {
      display flex
      flex-wrap wrap
      gap: 10px
    }

    &-value {
      flex-shrink 0
      display flex
      align-items center
      justify-content center
      min-width: 40px
      height: 40px
      padding 0 10px
      border: 2px solid var(--border-color);
      border-radius: var(--big-radius)
      transition var(--transition)

      &:not(&--active):hover {
        border-color var(--dark-light)
      }

      &--active {
        border-color var(--blue)
      }

      &--disabled {
        color var(--border-color)
        position relative
        pointer-events none
        cursor default
        overflow hidden

        &::after {
          content ""
          display block
          border-top 1px solid var(--border-color)
          transform: rotate(-45deg)
          position absolute
          top: 50%
          left -50%
          width 200%
        }
      }
    }
  }

  &__brand {
    display flex
    align-items center
    gap: 15px
    padding 10px

    &-img {
      width 80px
      height: 80px
      border-radius var(--big-radius)

      img {
        object-fit contain
      }
    }

    &-info {
      display flex
      flex-direction column
      gap: 5px
    }
  }

  &__favorite-add {
    align-self flex-end
    display flex
    align-items center
    gap: 5px
    padding 5px 10px
    border-radius var(--small-radius)
    font-size: 0.875em
    line-height: 20px;
    color: var(--dark-light);
    +below(720px) {
      width 100%
    }

    &.active,
    &:hover {
      color var(--blue)

      .icon svg path {
        fill var(--blue)
      }
    }

    .icon {
      width: 18px
      height: 18px

      svg path {
        transition var(--transition)
        fill var(--dark-light)
      }
    }
  }

  &__buy {
    padding 0
  }

  &__prices {
    display flex
    flex-flow row wrap
    align-items center
    gap: 15px 20px
    padding 20px
  }

  &__bonus {
    width 100%
    display flex
    align-items center
    gap: 5px

    &-price {
      font-weight: 700;
      font-size: 1.125em;
      line-height: 1.55
      color: var(--white)
      background: var(--red)
      border-radius: var(--small-radius)
      padding 0 5px
    }

    &-percent {
      font-size: 0.75em
      line-height: 14px;
      color: var(--red);
    }
  }

  &__price {
    font-weight: 700;
    font-size: 2.125em
    line-height: 28px;
    white-space nowrap
  }

  &__old-price {
    font-weight: normal;
    font-size: 1.375em
    line-height: 1.25
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    text-decoration-color var(--red)
    color var(--dark-light)
  }

  &__actions {
    padding 15px
    display flex
    flex-wrap wrap
    gap: 10px

    .btn {
      width 100%
    }
  }

  &__pros {
    display flex
    flex-direction column
  }

  &__plus {
    display flex
    align-items center
    gap: 10px
    font-size: 0.875em
    line-height: 20px;

    .icon {
      padding 5px
      width: 28px
      height: 28px

      svg path {
        fill var(--dark)
      }
    }
  }

  &__info {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap var(--gap)
    +below(990px) {
      grid-template-columns 1fr
    }

    &--no-specs {
      grid-template-columns 1fr
    }
  }

  &__specs {
    display flex
    flex-direction column
    gap: 10px
    width 100%
  }

  &__spec {
    display flex
    align-items baseline
    gap: 10px

    &-title {
      flex-shrink 0
    }

    &-line {
      flex-grow 1
      height 1px
      border-bottom: 1px dashed var(--border-color);
    }

    &-value {
      text-align right
    }

    a {
      color var(--blue)
    }
  }
}
</style>
