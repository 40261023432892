<template>
  <div class="product-gallery">
    <ProductParamSlider
      v-if="items && items.length"
      v-slot="{ item }"
      :items="items"
      :slider-options="sliderOptions"
    >
      <label
        class="product-gallery__slider-img"
        :class="{ 'product-gallery__slider-img--active': active === item.id }"
      >
        <input type="radio" v-show="false" :value="item.id" v-model="active" />
        <ImgComponent :img="item" width="400" height="400" :lazy="false" />
      </label>
    </ProductParamSlider>
    <div class="product-gallery__image">
      <div class="product-gallery__image-ratio">
        <div class="ratio-container">
          <ImgZoomComponent :head_img="activeItem">
            <ImgComponent :img="activeItem" :lazy="false" />
          </ImgZoomComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductParamSlider from "components/product/ProductParamSlider.vue";
import ImgZoomComponent from "components/ImgZoomComponent.vue";

export default {
  name: "ProductGalleryComponent",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    head_img: Object,
  },
  data() {
    return {
      active: null,
      sliderOptions: {
        direction: "horizontal",
        slidesPerView: "auto",
        spaceBetween: 10,
        pagination: false,
        clickable: false,
        breakpoints: {
          400: {
            direction: "vertical",
          },
        },
      },
    };
  },
  computed: {
    activeItem() {
      return this.items.find((i) => i.id === this.active) || this.head_img;
    },
  },
  created() {
    if (this.items && this.items.length) {
      this.active = this.items[0].id;
    }
  },
  watch: {
    items() {
      if (this.items && this.items.length) {
        this.active = this.items[0].id;
      }
    },
  },
  components: { ImgZoomComponent, ProductParamSlider },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.product-gallery {
  display flex
  align-items flex-start
  gap 30px
  +below(720px) {
    gap 15px
  }

  .param-slider {
    width 80px
    height 450px
    flex-shrink: 0;
    +below(620px) {
      width 60px
      height 360px
    }
    +below(480px) {
      height 300px
    }
    +below(400px) {
      height auto
      width 100%
    }

    &__arrow {
      display none
    }
  }

  &__slider-img {
    display flex
    align-items center;
    justify-content center
    background var(--white)
    border-radius: var(--big-radius)
    border 2px solid transparent
    width 80px
    height 80px
    overflow hidden
    cursor pointer
    transition border-color var(--transition)
    +below(620px) {
      width 60px
      height 60px
    }

    &:not(&--active):hover {
      border-color var(--main-o5)
    }

    &--active {
      border-color var(--main);
    }

    img {
      max-width 100%
      max-height 100%
      object-fit contain
      object-position center
    }
  }

  &__image {
    height auto
    width 100%
    max-width 100%
    display flex
    justify-content center
    align-items center
    overflow hidden
    border-radius: var(--big-radius)

    &-ratio {
      ratio(1, 1, contain)
    }

    img {
      background-color var(--white)
    }
  }
}
</style>
