<template>
  <div class="default-modal sizes-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title">{{ size_table.title || "Таблица размеров" }}</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <IconComponent name="close-square" />
      </button>
    </div>
    <div v-if="size_table.sizes && size_table.sizes.length" class="default-modal__body sizes-modal__body">
      <table class="table">
        <thead>
          <tr>
            <th>Размер</th>
            <th v-for="(item, i) in size_table.sizes[0].size_values" :key="i">
              {{ item.size_table_header.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in size_table.sizes" :key="i">
            <td>{{ item.attribute_value.value }}</td>
            <td v-for="(val, ind) in item.size_values" :key="ind">{{ val.value }}</td>
          </tr>
        </tbody>
      </table>
      <div class="sizes-modal__instructions">
        <div class="sizes-modal__instruction">
          <ImgComponent src="/static/images/size-1.png" class="sizes-modal__instruction-image" />
          <span class="sizes-modal__instruction-title">
            1. Встаньте на лист бумаги и обведите контур стопы.
          </span>
        </div>
        <div class="sizes-modal__instruction">
          <ImgComponent src="/static/images/size-2.png" class="sizes-modal__instruction-image" />
          <span class="sizes-modal__instruction-title">
            2. На полученном контуре измерьте отрезок от самого длинного пальца до пятки. Это и есть длина
            стопы, указанная в таблице.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SizesTableModal",
  props: {
    size_table: Object,
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.sizes-modal {
  &__body {
    overflow-x auto
    display flex
    flex-direction column
    gap 30px
  }

  &__instructions {
    display flex
    flex-direction column
    gap 15px
    max-width 640px
  }

  &__instruction {
    display flex
    align-items center
    gap 15px
    +below(580px) {
      flex-direction column
      align-items flex-start
    }
  }

  &__instruction-image {
    max-width 150px
    max-height 92px
  }

  &__instruction-title {
    font-size .875rem
    line-height 20px
  }
}
</style>
